@import '../partials';

.global-callout-banner {
  background-color: $green;
  padding: rem(36);

  @include bp(sm) {
    padding: rem(36) $component-spacing;
  }

  &__boundary {
    max-width: rem(1140);
    margin: 0 auto;
    align-items: flex-start;
    flex-direction: column;
    display: flex;

    @include bp(md) {
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      flex-wrap: nowrap;
    }
  }

  &__text {
    color: $black;
    font-size: rem(18);
    line-height: rem(36);
    font-family: $sans-serif-body-font;
    max-width: rem(675);

    @include bp(md) {
      font-size: rem(22);
    }
  }

  &__link {
    color: $black;
    background-color: $green;
    text-transform: uppercase;
    font-size: rem(16);
    font-weight: $font-weight-bold;
    padding: rem(20);
    border: 2px solid $black;
    text-align: center;
    margin-top: rem(24);
    margin-left: 0;
    transition-property: color, background-color;
    transition-duration: $fast-duration;

    @include bp(md) {
      white-space: nowrap;
      font-size: rem(18);
      margin-top: 0;
      margin-left: rem(24);
    }

    &:hover {
      color: $white;
      background-color: $black;
    }
  }
}
